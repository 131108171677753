// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

@import "@ng-select/ng-select/themes/material.theme.css";
@import "@videogular/ngx-videogular/fonts/videogular.css";
// @import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "assets/styles/ng-select.scss";
@import "assets/styles/owlDate.scss";
@import "modified-theming.scss";

$dynamic-theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  ),
);

$dynamic-theme-accent: (
  50: var(--theme-accent-50),
  100: var(--theme-accent-100),
  200: var(--theme-accent-200),
  300: var(--theme-accent-300),
  400: var(--theme-accent-400),
  500: var(--theme-accent-500),
  600: var(--theme-accent-600),
  700: var(--theme-accent-700),
  800: var(--theme-accent-800),
  900: var(--theme-accent-900),
  A100: var(--theme-accent-A100),
  A200: var(--theme-accent-A200),
  A400: var(--theme-accent-A400),
  A700: var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700),
  ),
);

$dynamic-theme-warn: (
  50: var(--theme-warn-50),
  100: var(--theme-warn-100),
  200: var(--theme-warn-200),
  300: var(--theme-warn-300),
  400: var(--theme-warn-400),
  500: var(--theme-warn-500),
  600: var(--theme-warn-600),
  700: var(--theme-warn-700),
  800: var(--theme-warn-800),
  900: var(--theme-warn-900),
  A100: var(--theme-warn-A100),
  A200: var(--theme-warn-A200),
  A400: var(--theme-warn-A400),
  A700: var(--theme-warn-A700),
  contrast: (
    50: var(--theme-warn-contrast-50),
    100: var(--theme-warn-contrast-100),
    200: var(--theme-warn-contrast-200),
    300: var(--theme-warn-contrast-300),
    400: var(--theme-warn-contrast-400),
    500: var(--theme-warn-contrast-500),
    600: var(--theme-warn-contrast-600),
    700: var(--theme-warn-contrast-700),
    800: var(--theme-warn-contrast-800),
    900: var(--theme-warn-contrast-900),
    A100: var(--theme-warn-contrast-A100),
    A200: var(--theme-warn-contrast-A200),
    A400: var(--theme-warn-contrast-A400),
    A700: var(--theme-warn-contrast-A700),
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tema-primary: mat.m2-define-palette($dynamic-theme-primary);
$tema-accent: mat.m2-define-palette($dynamic-theme-accent);

// The warn palette is optional (defaults to red).
$tema-warn: mat.m2-define-palette($dynamic-theme-warn);

$typography: mat.m2-define-typography-config(
  $font-family: "Nunito",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tema-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $tema-primary,
      accent: $tema-accent,
      warn: $tema-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

$tema-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $tema-primary,
      accent: $tema-accent,
      warn: $tema-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

// Colores
:root {
  --primary-color: var(--theme-primary-500);
  --accent-color: var(--theme-accent-500);
  --warn-color: var(--theme-warn-500);
  --primary-contrast-color: var(--theme-primary-contrast-500);
  --accent-contrast-color: var(--theme-accent-contrast-500);
  --warn-contrast-color: var(--theme-warn-contrast-500);
}

.text-on-primary {
  color: var(--primary-contrast-color) !important;
}
.text-on-accent {
  color: var(--accent-contrast-color) !important;
}
.text-on-warn {
  color: var(--warn-contrast-color) !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tema-light-theme);

//////// Custom Styles ////////

.dark-theme {
  @include mat.all-component-colors($tema-dark-theme);
  color: white;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Nunito";
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column-flex-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-space-evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.no-info {
  font-style: italic;
  font-weight: lighter;
  color: grey;
  font-size: medium;
}

.bg-70 {
  background-color: color-mix(
    in srgb,
    var(--theme-primary-500) 50%,
    rgba(255, 255, 255, 0.3)
  ) !important;
}

.bg-85 {
  background-color: color-mix(
    in srgb,
    var(--theme-primary-500) 85%,
    rgba(255, 255, 255, 0.3)
  ) !important;
}

.ac-85 {
  background-color: color-mix(
    in srgb,
    var(--theme-accent-500) 85%,
    rgba(255, 255, 255, 0.3)
  ) !important;
  // color: var(--theme-accent-contrast-500) !important;

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-body {
    color: var(--theme-accent-contrast-500) !important;
  }
}

.magico-85 {
  background-color: color-mix(
    in srgb,
    var(--mat-select-panel-background-color) 85%,
    rgba(255, 255, 255, 0.3)
  ) !important;
}

.magico-100 {
  background-color: var(--mat-select-panel-background-color) !important;
}

.titulo {
  font-size: 2rem;
  font-weight: 700;
  border-left: 8px solid var(--theme-accent-500);
  padding-left: 10px;
}

.subtitulo {
  font-size: 1.5rem;
  font-weight: 700;
  border-left: 8px solid var(--theme-accent-500);
  padding-left: 10px;
}

// Snackbar
.mat-mdc-snack-bar-container {
  .mat-mdc-button {
    color: white !important;
  }
  &.app-notification-error {
    --mat-mdc-snack-bar-button-color: white !important;
    --mdc-snackbar-supporting-text-color: #ffffff !important;
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.app-notification-success {
    --mat-mdc-snack-bar-button-color: white !important;
    --mdc-snackbar-supporting-text-color: #ffffff !important;
    --mdc-snackbar-container-color: #43a446;
  }

  &.app-notification-warn {
    --mat-mdc-snack-bar-button-color: white !important;
    --mdc-snackbar-supporting-text-color: #ffffff !important;
    --mdc-snackbar-container-color: #ff9800;
  }
}

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/// Esconder cosas de google en el mapa
// Maps
* {
  google-map > div > div > div > div:last-child {
    display: none;
  }

  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }
  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  .gm-style-mtc {
    display: none;
  }
}

//// Infowindow

.gm-style-iw {
  top: 0 !important;
  left: 0 !important;
  color: var(--mat-sidenav-content-text-color) !important;
  background: var(--mat-select-panel-background-color) !important;
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
  border: 1px solid rgba(72, 181, 233, 0.6);
  border-radius: 2px 2px 0 0;
  .button {
    display: none;
  }
}

.gm-style {
  .gm-style-iw-d::-webkit-scrollbar-track-piece {
    background: var(--mat-select-panel-background-color);
  }
  .gm-style-iw-d::-webkit-scrollbar-track {
    background: var(--mat-select-panel-background-color);
  }
  .gm-style-iw-tc::after {
    background: var(--mat-select-panel-background-color);
  }
}

// Scroll Bar
.hideScroll::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  margin-left: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-clip: content-box;
  border: 3px solid transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-clip: content-box;
  border: 3px solid transparent;
}

// Theme

::-webkit-scrollbar {
  // background-color: var(--bg-color-solid);
  background-color: transparent;
}
::-webkit-scrollbar-track {
  // background-color: var(--bg-color-solid);
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--theme-accent-500);
}

.mat-drawer-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Progress Bar
// .mdc-linear-progress__buffer-bar {
//   background-color: var(--mdc-filled-text-field-container-color) !important;
// }
.mat-mdc-progress-bar {
  .mdc-linear-progress__bar-inner {
    border-color: var(--theme-primary-100);
  }
}
.mat-mdc-progress-bar.mat-accent {
  .mdc-linear-progress__bar-inner {
    border-color: var(--theme-accent-100);
  }
}
.mat-mdc-progress-bar.mat-warn {
  .mdc-linear-progress__bar-inner {
    border-color: var(--theme-warn-100);
  }
}

mat-form-field {
  width: 100%;
}

.mat-menu-panel {
  max-width: 600px !important;
  color: var(--mat-sidenav-content-text-color) !important;
}

.mat-mdc-menu-panel {
  max-width: 600px !important;
  color: var(--mat-sidenav-content-text-color) !important;
}
//Titulo del modal ocupe todo el ancho
.mat-mdc-card-header-text {
  width: 100%;
}

// Saca el color gris del expansion del menu
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
    background: none !important;
  }
}

// Quita la sombra de los botones mat fab
.mat-mdc-fab {
  box-shadow: none !important;
}

// Deshabilita las flechas de los input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Safari and Chrome */
}

.cardContentDialog {
  max-height: 60vh;
}
.cardContentFullscreen {
  height: calc(100vh - 290px);
}
.cardContentPestaña {
  height: calc(100vh - 242px);
  display: block;
  overflow: auto;
}
