.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: var(--theme-accent-contrast-500);
}
.ng-select.ng-select-focused {
  .ng-select-container {
    .ng-value-container .ng-placeholder {
      color: var(--theme-primary-500);
      opacity: 1;
    }

    .ng-arrow-wrapper .ng-arrow {
      color: var(--theme-primary-500);
      opacity: 1;
    }
  }

  .ng-select-container:after {
    border-color: var(--theme-primary-500);
    opacity: 1;
  }
}
.ng-select {
  background: var(--mdc-filled-text-field-container-color);
  padding: 0;
  margin-bottom: 22px;
  height: 56px;
  border-radius: 4px 4px 0 0;

  .ng-select-container {
    height: 100%;
    padding: 0 1em;
    color: var(--mat-sidenav-content-text-color);

    .ng-value-container {
      .ng-placeholder {
        opacity: 0.65;
        color: var(--mat-sidenav-content-text-color);
      }
    }

    .ng-arrow-wrapper .ng-arrow {
      opacity: 0.65;
      color: var(--mat-sidenav-content-text-color);
      margin-right: 0px;
      margin-bottom: 9px;
    }
  }

  .ng-select-container:after {
    border-bottom: 1px solid var(--mat-sidenav-content-text-color);
    opacity: 0.45;
  }

  .ng-clear {
    color: var(--mat-sidenav-content-text-color) !important;
  }

  .ng-optgroup {
    color: color-mix(
      in srgb,
      var(--mat-sidenav-content-text-color),
      rgba(255, 255, 255, 0.3)
    ) !important;
  }

  input {
    color: var(--mdc-filled-text-field-input-text-color);
    font-size: 1em;
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    // background-color: var(--mat-option-selected-state-label-text-color);
    background-color: var(--theme-accent-500);
  }
}

.ng-dropdown-panel {
  background: var(--mat-select-panel-background-color);
  padding: 0.5em;
  .ng-dropdown-panel-items {
    .ng-option {
      color: var(--mdc-filled-text-field-input-text-color);
    }
    .ng-option.ng-option-selected {
      color: var(--mdc-filled-text-field-input-text-color);
    }
    .ng-option.ng-option-marked {
      color: var(--mdc-filled-text-field-input-text-color);
    }
  }
  .ng-option {
    padding: 0.5em 0;
  }

  .ng-star-inserted {
    color: var(--mdc-filled-text-field-input-text-color);
  }
}
